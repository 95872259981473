import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './msp/app.module';
import { environment } from './environments/environment';
import {googleAnalyticsHeadScripts} from './assets/js/script';
if (environment.production) {
    enableProdMode();
}
googleAnalyticsHeadScripts(environment.gTag_msp);
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
