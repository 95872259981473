import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { UserDetailFormComponent } from './user-detail-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { AlertModule } from '../alert/alert.module';
import { PreloaderModule } from '../preloader/preloader.module';
@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        PreloaderModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    declarations: [UserDetailFormComponent],
    exports: [UserDetailFormComponent]
})
export class UserDetailFormModule { }
