import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Router } from '@angular/router';

import { AppRoutingModule } from './app.routes';
import { SharedModule } from 'shared/components/shared.module';
import { SDKBrowserModule } from 'shared/sdk';
import { Error404Module } from 'shared/views/error/404/404.module';
import { Error403Module } from 'shared/views/error/403/403.module';
import { DownloadsModule } from 'shared/views/downloads/downloads.module';

import { AppComponent } from './app.component';
import { AppStateService } from 'shared/services/app-state.service';
import { AuthGuard, ActiveUserAuthGuard } from 'shared/services/auth-guard.service';
import { AuthService } from 'shared/services/authentication.service';
import { AuthorizationService } from 'shared/services/authorization.service';
import { InterceptorService } from '../shared/services/token.interceptor';
import { CommonService } from 'shared/services/common.service';
import { ModalService } from 'shared/services/modal.service';
import { AlertService } from 'shared/services/alert.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { WorkflowService } from 'shared/services/workflow.service';
import { MessageService } from 'shared/services/message.service';
import { DocumentService } from 'shared/services/document.service';
import { DepartmentService } from 'shared/services/department.service';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { DataFormatter } from 'shared/libs/dataFormatter';
import { SocketService } from 'shared/services/socket.service';
import { Angulartics2Module } from 'angulartics2';
import { CookieService } from 'ngx-cookie-service';
import { CallbackComponent } from '../shared/views/callback/callback.component';
import { NotificationService } from './../shared/services/notification.service';
import { GpsSocketService } from './../shared/services/gps-socket.service';
import { MessageSocketService } from './../shared/services/message-socket.service';
import { NotificationSocketService } from 'shared/services/notification-socket.service';
import { SharedLoginLogModule } from 'shared/components/shared-login-log/shared-login-log.module';
import { SharedLoginLogComponent } from 'shared/components/shared-login-log/shared-login-log.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PublicCaqModule } from 'shared/components/public-caq/public-caq.module';
import { UserDetailFormModule } from 'shared/components/user-detail-form/user-detail-form.module';
@NgModule({
  declarations: [AppComponent, CallbackComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    SDKBrowserModule.forRoot(),
    RouterModule,
    AppRoutingModule,
    SharedModule,
    Error404Module,
    Error403Module,
    HttpClientModule,
    DownloadsModule,
    Angulartics2Module.forRoot(),
    SharedLoginLogModule,
    MatDialogModule,
    PublicCaqModule,
    UserDetailFormModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationService,
      multi: true
    },
    AppStateService,
    AuthGuard,
    AuthService,
    AlertService,
    ActiveUserAuthGuard,
    CommonService,
    ModalService,
    PreloaderService,
    WorkflowService,
    MessageService,
    DocumentService,
    DepartmentService,
    DataFormatter,
    SocketService,
    CookieService,
    NotificationService,
    GlobalFilterService,
    GpsSocketService,
    MessageSocketService,
    NotificationSocketService
  ],
  bootstrap: [AppComponent],
  entryComponents: [SharedLoginLogComponent]
})
export class AppModule { }
