import { ContactApi } from './../../sdk/services/custom/Contact';
import { AlertService } from 'shared/services/alert.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';


@Component({
  selector: 'app-user-detail-form',
  templateUrl: './user-detail-form.component.html',
  styleUrls: ['./user-detail-form.component.css']
})
export class UserDetailFormComponent implements OnInit {
  generateForm: FormGroup;
  formErrors: any = {};
  validationCAQMessages = {};
  contactSfdcId: any;
  
  constructor(  private _preloaderService: PreloaderService,
    private _alertService: AlertService,
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _contactApi: ContactApi) {
      this._route.params.subscribe(params => {
        if (params['usersfdcId']) {
            this.contactSfdcId = params['usersfdcId'];
        }
    });
     }

  ngOnInit() {
    this.createForms();
    this.setFormErrors();
  }

  createForms() {
   this.generateForm = this._fb.group({
        Birthdate: ['', [Validators.required]],
        Date_of_Joining__c: ['', [Validators.required]],
    });
    this.generateForm.valueChanges.subscribe(data => this.onFormChanged());
}

onFormChanged(setDirty?: any) {
  if (!this.generateForm) { return; }
  const form = this.generateForm;
  // tslint:disable-next-line:forin
  for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (setDirty) {
          this.setDirty(field)
      }
      if (control && control.dirty && !control.valid) {
          const messages = this.validationCAQMessages[field];
          // tslint:disable-next-line:forin
          for (const key in control.errors) {
              this.formErrors[field] += (messages[key]) ? messages[key] + ' ':'';
          }
      }
  }
}

setDirty(field) {
  this.generateForm.get(field).markAsTouched();
  this.generateForm.get(field).markAsDirty();
}


setFormErrors() {
  this.formErrors = {
      'Birthdate': '',
      'Date_of_Joining__c': ''
  };
  this.validationCAQMessages = {
      'Birthdate': {
          'required': 'Date of birth is required.'
      },
      'Date_of_Joining__c': {
          'required': 'Date of joining is required.',
      }
  };
}


onSubmit() {
  this.onFormChanged(true);
  if (this.generateForm.invalid) {
      return;
  };
  this._alertService.clear();
  const finalObj = {
    'Birthdate': moment(this.generateForm.value['Birthdate']).format('YYYY-MM-DD'),
    'Date_of_Joining__c': moment(this.generateForm.value['Date_of_Joining__c']).format('YYYY-MM-DD'),
    'sfdcId': this.contactSfdcId
  }
  this._preloaderService.showPreloader();
    this._contactApi.saveContactDetails(finalObj)
      .subscribe((res) => {
        this._preloaderService.hidePreloader();
        this._alertService.success(res.message);
        this.generateForm.reset();
      }, err => {
        this._preloaderService.hidePreloader();
        this._alertService.error(err.message);
        console.log(err);
      }, () => {
        this._preloaderService.hidePreloader();
      });
  
}

}
